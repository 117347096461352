import * as React from "react"
import { Container } from "react-bootstrap"
import Navigation from "../components/Navigation"
import Section from "../components/Section"
import Seo from "../components/Seo"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Seo
        title='Matthe815.dev'
      />

      <Navigation />

      <Container>
        <Section>
          <Section.Header>
            <Section.Tab text='Not Found!' active={true} />
          </Section.Header>

          <Section.Body>
            <Section.Tab text='Try searching elsewhere' />
          </Section.Body>
        </Section>
      </Container>
    </main>
  )
}

export default NotFoundPage
